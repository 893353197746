import React, { memo, useEffect, useMemo, useState, useCallback } from 'react'
import Table from 'react-bootstrap/Table'
import Pagination from 'react-bootstrap/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import _map from 'lodash/map'
import _range from 'lodash/range'
import _slice from 'lodash/slice'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import IconCaret from '../../assets/claim-portal/icon-caret.svg'
import IconForward from '../../assets/claim-portal/icon-forward.svg'
import { useAdminContext } from '../../context/admin-context'
import { Spinner } from 'react-bootstrap'

import './styles.scss'

const Frame = 4

const AwesomeTable = ({
  headers,
  emptyText,
  data,
  pageSize = 50,
  renderRow,
  onChangePage,
  size,
  sortingValues,
  current,
  defaultSearchConfig = { index: 1, isDescending: false, disable: [] },
  isLoading,
  isNoPagination = false,
}) => {
  const { orderLoading } = useAdminContext()
  const [currentPage, setCurrentPage] = useState(1)
  const [sortConfig, setSortConfig] = useState({})
  const totalPages = useMemo(() => {
    if (data?.pagination?.totalPages) {
      return data.pagination.totalPages
    }
    return Math.ceil((size > 0 ? size : data?.length) / pageSize)
  }, [data, pageSize, size])
  const startPos = useMemo(() => (currentPage - 1) * pageSize, [currentPage, pageSize])
  const displayItems = useMemo(() => {
    if (data?.pagination && data?.claims) {
      return data.claims
    }

    if (isNoPagination) {
      return data
    } else {
      return size > 0 ? _get(data, currentPage, []) : _slice(data, startPos, startPos + pageSize)
    }
  }, [size, data, currentPage, startPos, pageSize, isNoPagination])

  const loading = useMemo(() => isLoading || orderLoading, [isLoading, orderLoading])

  const onClickSearchColumn = useCallback(
    (indexColumn) => {
      if (_get(sortConfig, 'disable', []).includes(indexColumn) === false) {
        const { index, isDescending } = sortConfig

        setSortConfig({
          ...sortConfig,
          index: indexColumn,
          isDescending: index === indexColumn ? !isDescending : isDescending,
        })
      }
    },
    [sortConfig, setSortConfig]
  )

  const withSortingResults = useMemo(() => {
    const { index, isDescending } = sortConfig
    let sortFunction = _get(sortingValues, index, '')

    if (typeof sortFunction !== 'function') {
      sortFunction = (row) => _get(row, _get(sortingValues, index), '')
    }

    return displayItems.sort((a, b) => {
      const first = sortFunction(a)
      const second = sortFunction(b)

      if (typeof first == 'string') {
        return first.localeCompare(second) * (isDescending ? -1 : 1)
      }

      return (first > second ? -1 : 1) * (isDescending ? -1 : 1)
    })
  }, [displayItems, sortConfig, sortingValues])

  const isEmptyResult = useMemo(() => _isEmpty(withSortingResults), [withSortingResults])

  useEffect(() => {
    setCurrentPage(Number(current) || 1)
  }, [current])

  useEffect(() => {
    setSortConfig(defaultSearchConfig)
  }, [JSON.stringify(defaultSearchConfig)])

  const onChangeCurrentPage = (page) => {
    onChangePage(page)
  }

  const startPage = useMemo(() => {
    if (currentPage < Frame / 2) {
      return 0
    }

    if (currentPage > totalPages - Frame) {
      return Math.max(totalPages - Frame, 0)
    }

    return Math.max(currentPage - (Frame - 2) + 1, 0)
  }, [currentPage, Frame, totalPages])

  return (
    <>
      <Table className="awesome-table-wrapper" responsive hover>
        <thead className="header text-center">
          <tr className="header-item">
            {_map(headers, (title, index) => (
              <th key={index} onClick={() => onClickSearchColumn(index)} className="header-text">
                {title} &nbsp;
                {_get(sortConfig, 'disable', []).includes(index) === false ? (
                  <span
                    className={`header-icon ${
                      sortConfig && sortConfig.index === index ? '' : 'no-display'
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        sortConfig && sortConfig.isDescending ? faSortAmountDown : faSortAmountUp
                      }
                    />
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        {loading === false && <tbody>{_map(withSortingResults, renderRow)}</tbody>}
      </Table>
      {isEmptyResult === true && loading === false && (
        <div className="text-center pt-2 empty-text">{emptyText}</div>
      )}
      {loading && (
        <div className="d-flex w-full justify-content-center py-5">
          <Spinner animation="border" variant="success" />
        </div>
      )}
      {totalPages > 1 && isNoPagination === false && (
        <Pagination className="justify-content-end">
          <Pagination.First onClick={() => onChangeCurrentPage(1)} className="pagination-first">
            <img alt="forward" src={IconForward} className="forward-prev" />
          </Pagination.First>
          <Pagination.Prev
            onClick={() => currentPage > 1 && onChangeCurrentPage(currentPage - 1)}
            className="pagination-prev"
          >
            <img alt="caret" src={IconCaret} className="caret-prev" />
          </Pagination.Prev>
          {_map(_range(totalPages).slice(startPage, startPage + Frame), (page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => onChangeCurrentPage(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => currentPage < totalPages && onChangeCurrentPage(currentPage + 1)}
            className="pagination-next"
          >
            <img alt="caret" src={IconCaret} className="caret-next" />
          </Pagination.Next>
          <Pagination.Last
            onClick={() => onChangeCurrentPage(totalPages)}
            className="pagination-last"
          >
            <img alt="forward" src={IconForward} className="forward-next" />
          </Pagination.Last>
        </Pagination>
      )}
    </>
  )
}

export default memo(AwesomeTable)
