import request from '@/support/utils/request'

type PathParameter = string | number

const Store = {
  basePath: '/stores',
  getHeader(token?: string) {
    if (!token) {
      return {}
    }

    return {
      Authorization: `Bearer ${token}`,
    }
  },
  joinPath(...paths: PathParameter[]) {
    return [this.basePath, ...paths].join('/')
  },
  async get(id: number) {
    return (await request.get(this.joinPath(id))).data
  },
  async list() {
    return (await request.get(this.basePath)).data
  },
  async update(id: number, update: object) {
    return (await request.put(this.joinPath(id), update)).data
  },
  async settings(id: number) {
    return (await request.get(this.joinPath(id, 'settings'))).data
  },
  async getClaims(
    id: number,
    data: {
      new?: boolean
      open?: boolean
      page?: number
      pageSize?: number
      startDate?: string
      endDate?: string
    },
    token?: string
  ) {
    const headers = this.getHeader(token)
    return (
      await request.post(this.joinPath(id, 'claims-v2'), data, {
        headers,
      })
    ).data
  },
  async getOpenAndNewClaimCounts(id: number, token?: string) {
    const headers = this.getHeader(token)
    return (await request.get(this.joinPath(id, 'new-and-open-claim-counts'), { headers })).data
  },
  async healthCheck() {
    return (
      await request.get('/health-check', {
        'axios-retry': {
          retries: 1,
          retryDelay: (retryCount) => {
            return retryCount * 1000
          },
          retryCondition: (error) => {
            return error.response?.status === 500
          },
          onRetry: (retryCount, error, config) => {
            console.log(`Retry ${retryCount} for ${config.url}`)
          },
        },
      })
    ).data
  },
}

export default Store
